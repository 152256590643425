.sectionContainer {
    // background: #0F172A;
    margin-top: 22.5rem;
}

.sectionHeading {
    font-weight: 700;
    // font-size: 56px;
    font-size: 3rem;
    line-height: 5rem;
    /* or 143% */

    text-align: center;
    letter-spacing: -0.04em;

    // color: #FFFFFF;
}

.featureBox {
    background: rgba(255, 251, 163, 0.35);
    border-radius: 24px;
    background-size: cover;
}
.featureBoxDispute {
    // border-radius: 2rem;
    // background: url('/assets/featureBg.png') no-repeat;
    background: rgba(246, 212, 252, 0.35);
    border-radius: 1.5rem;
    background-size: cover;
}
.title{
    font-weight: 300;
font-size: 1.25rem;
line-height: 1.5625rem;

color: #595A5B;
}
.label{
    font-weight: 600;
font-size: 1rem;
line-height: 1.25rem;
/* identical to box height */


color: #121212;
}
.text{
    font-weight: 300;
// font-size: 0.75rem;
font-size: 0.95rem;

line-height: 0.9375rem;

color: #121212;
}
.featureHead {
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 1.5625rem;
    /* identical to box height */
    color: #E19A46;
}

.featureHeadMain {
    font-weight: 600;
    // font-size: 3.25rem;
    font-size: 2.25rem;

    line-height: 4.0625rem;
    /* identical to box height */
    color: #121212;
}

.pointerContainer {
    @apply flex flex-row mb-12;
}
.pointerContainer > div.pointerIcon {
    min-width: 0.9375rem;
    min-height: 0.875rem;
    @apply mt-1.5;
}

.pointerContainer > div.pointerText {
    @apply ml-4;
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 1.875rem;
    color: #121212;
}

.featureImage {
    width: 50%;
    //width: 39.3125rem;
    //height: 32.5rem;
}

.mobilebgYImage{
    background: rgba(255, 251, 163, 0.35);
    border-radius: 1rem;
}
.mobilebgImage{
    background: rgba(246, 212, 252, 0.35);
border-radius: 1rem;
}
.mobileTitle{
    font-weight: 500;
font-size: 1.5rem;
line-height: 1.875rem;
/* or 125% */

text-align: center;
letter-spacing: -0.04em;

color: #121212;

}
.mobileText{
    font-weight: 300;
font-size: 0.875rem;
line-height: 1.125rem;
text-align: center;

color: #595A5B;
}

.mobileLandingPage{
    background: url("./assets/RectangleForMobile.svg");
    background-repeat: no-repeat;
    background-size: cover;
}
.mobileLandingPageY{
    background: rgba(225, 210, 70, 0.1);
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 0.75rem;
}


@media (min-width: 360px) and (max-width: 1023px) {
    .featureBox {
        margin-left: 1.5rem;
        margin-right: 1.125rem;
    }
    .bgImage{
        width: 17.5625rem;
        height: 13.1875rem;
        margin: 0 1.125rem;
    }
    .featureHeadMain{
        font-weight: 500;
        font-size: 1.5rem;
        line-height: 1.875rem;
        display: flex;
        justify-content: center;
        letter-spacing: -0.04em;
        text-align: center;
    }
    .title{
        font-weight: 300;
        font-size: 0.875rem;
        line-height: 1.125rem;
        text-align: center;
        color: #595A5B;
    }
    .mobileLandingPageY{
        display: flex;
        justify-content: center;
    }
    .mobileLandingPage{
        display: flex;
        justify-content: center;
    }
}