.tableCss {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
}
  
.tdandthCss {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
}

.error {
    border: 1px solid red;
    text-align: left;
    padding: 8px;
}

/* tr:nth-child(even) {
    background-color: #dddddd;
} */