.container {
    width: 100%;
    /* height: 100vh; */
    display: flex;
    /* justify-content: space-between; */
    flex-wrap: wrap;
    /* align-items: center; */
}

.itemr1 {
    transition: all 0.2s;
    cursor: pointer;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    width: 80%;
    margin: 0 auto;
    height: 80px;
}
.itemr2 {
    transition: all 0.2s;
    cursor: pointer;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    width: 53%;
    height: 80px;
}

.margin4{
    margin: 0 6.6% 0 auto;
}
.margin5{
    margin: 0 auto 0 6.6%;
}

.itemr1:hover {
    scale: 1.1;
}
.itemr2:hover {
    scale: 1.1;
}

.image {
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: rgb(250, 202, 202); */
    border-radius: 8px;
}

.image img {
    width: 80px;
    border-radius: 8px;
    height: 80px;
}

.text {
    background-color: white;
    border-radius: 8px;
    margin: auto;
}

.heading {
    width: 100%;
    height: 30%;
    text-align: center;
    font-size: 15px;
    font-weight: 600;
    color: rgb(109, 108, 108);
    margin-top: 7px;
}

.number {
    width: 100%;
    font-size: 25px;
    font-weight: 700;
    color: rgb(86, 61, 61);
    text-align: center;
}

@media (max-width: 1000px) {

    .image img {
        width: 60px;
        border-radius: 8px;
        height: 60px;
    }

    .image {
        display: flex;
        justify-content: center;
        align-items: center;
        /* background-color: rgb(250, 202, 202); */
        border-radius: 8px;
    }

    .heading {
        width: 100%;
        height: 30%;
        text-align: center;
        font-size: 10px;
        font-weight: 600;
        color: rgb(109, 108, 108);
        margin-top: 7px;
    }

    .number {
        width: 100%;
        font-size: 20px;
        font-weight: 700;
        color: rgb(86, 61, 61);
        text-align: center;
    }
}
