.dropBox{
    box-sizing: border-box;
    background: rgba(84, 140, 168, 0.08);
    height: 8.875rem;
    border: 0.0625rem dashed rgba(84, 140, 168, 0.08);
    border-radius: 1rem;
    align-items: center;
}
.agrement{
    line-height: 1.25rem;
    letter-spacing: -0.0313rem;
    color: #191D23;
}
